<template>
  <div class="mb-5">
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(1)"
      title="ด้านสังคม สัดส่วนครัวเรือนที่ไม่มีไฟฟ้าใช้ (%)"
      :fetchPromise="fetchHHShareWoEl"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(2)"
      title="ด้านเศรษฐกิจ การใช้พลังงานต่อคน (toe/คน)"
      :fetchPromise="fetchEnergyConsCom"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(3)"
      title="ด้านเศรษฐกิจ การใช้พลังงานต่อหน่วยของ GPP (toe/ล้านบาท)"
      :fetchPromise="fetchEnergyConsComRel"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(4)"
      title="ด้านสิ่งแวดล้อม ปริมาณการปล่อยก๊าซเรือนกระจกจากภาคพลังงานต่อคน (tCO2e/คน)"
      :fetchPromise="fetchGHGEmissionCap"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(5)"
      title="ด้านสิ่งแวดล้อม ปริมาณการปล่อยก๊าซเรือนกระจกจากภาคพลังงานต่อ GPP (tCO2e/ล้านบาท)"
      :fetchPromise="fetchGHGEmissionGPP"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import ChartBox from '@/components/ChartBox.vue';
import { get, isEmpty, head, range, forIn, find } from 'lodash';

export default {
  name: 'metric',

  components: {
    ChartBox,
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      topicNumber: '2.5',
      imageUrl: '',
      educationData: {
        title: 'ด้านสังคม สัดส่วนครัวเรือนที่ไม่มีไฟฟ้าใช้',
        unit: ' %',
        data: [
          ['ปี', 'สัดส่วนครัวเรือนที่ไม่มีไฟฟ้าใช้', 'ค่าเฉลี่ยระดับประเทศ'],
          ['2014', 80.5, 76.48],
          ['2015', 81.32, 77.02],
          ['2016', 81.64, 77.19],
          ['2017', 82.56, 77.65],
          ['2018', 83.05, 78.45],
        ],
      },
    };
  },

  created() {
    this.init();
  },

  computed: {
    ...mapGetters('peno', ['currentProvince']),
    ...mapGetters(['latestNationalPlan']),
  },

  methods: {
    ...mapActions('peno', ['fetchChartImageUrl']),
    ...mapActions('mondas', [
      'fetchIndecesEnergyConsCom',
      'fetchIndecesNational',
      'fetchIndeces',
    ]),
    chartIdentifier(number) {
      return this.topicNumber + '.' + number;
    },
    init() {
      this.fetchEnergyConsCom().then((result) => {
        this.commercialData = result;
      });
    },
    getYearQueryString(year, generalData) {
      const yearBackRangeIndex = 4;
      let minYear = generalData.min_year;
      let maxYear = generalData.max_year;
      if (minYear && maxYear) {
        let yearInt = parseInt(year);
        if (yearInt < minYear) {
          return null;
        }
        if (yearInt < maxYear) {
          maxYear = yearInt;
        } else {
          yearInt = maxYear;
        }
        if (yearInt - yearBackRangeIndex > minYear) {
          minYear = yearInt - yearBackRangeIndex;
        }
        const yearArray = range(minYear, maxYear + 1);
        const yearStringQuery = yearArray.join(',');
        return yearStringQuery;
      }
    },
    buildTableData(
      data,
      generalData,
      averageList,
      forceUnit = null,
      formattingValue = null
    ) {
      let titleIndex = get(generalData, 'index', null);
      if (!titleIndex) {
        titleIndex = get(generalData, 'index`', 'หัวข้อ');
      }
      const unit = forceUnit || generalData.unit;
      const title = titleIndex + ' ' + generalData.lists + ' ' + unit;
      const chartUnit = ' (' + unit + ')';
      const plotdata = [['ปี', generalData.lists, 'ค่าเฉลี่ยระดับประเทศ']];
      if (!isEmpty(data)) {
        forIn(data, (item, key) => {
          let average = find(averageList, { year: key }).value;
          average = formattingValue ? formattingValue(average) : average;
          const value = formattingValue
            ? formattingValue(item.value)
            : item.value;
          plotdata.push([key, value, average]);
        });
      } else {
        forIn(averageList, (item, key) => {
          const average = item.value;
          plotdata.push([item.year, 0, average]);
        });
      }
      const result = {
        title: title,
        unit: chartUnit,
        data: plotdata,
      };
      return result;
    },
    fetchData(nationalKey, key, forceUnit = null, formattingValue = null) {
      const currentYear = this.$options.filters.thai_year(
        this.latestNationalPlan.year
      );
      return this.fetchIndecesNational({
        year: currentYear,
        key: nationalKey,
      }).then((data) => {
        if (!isEmpty(data)) {
          const averageList = data;
          const generalData = head(averageList);
          const yearStringQuery = this.getYearQueryString(
            currentYear,
            generalData
          );
          return this.fetchIndeces({
            yearStringQuery: yearStringQuery,
            province: this.currentProvince,
            key: key,
          }).then((data) => {
            const result = this.buildTableData(
              data,
              generalData,
              averageList,
              forceUnit,
              formattingValue
            );
            return result;
          });
        } else {
          return null;
        }
      });
    },
    fetchHHShareWoEl() {
      return this.fetchData('RDN_HHShareWoEl', 'RDI_HHShareWoEl');
    },
    fetchEnergyConsCom() {
      return this.fetchData('RDN_EnergyConsCom', 'RDI_EnergyConsCom');
    },
    fetchEnergyConsComRel() {
      return this.fetchData('RDN_EnergyConsComRel', 'RDI_EnergyConsComRel');
    },
    fetchGHGEmissionCap() {
      return this.fetchData(
        'RDN_GHGEmissionCap',
        'RDI_GHGEmissionCap',
        'tCO2e/คน',
        (value) => {
          return value * 1000;
        }
      );
    },
    fetchGHGEmissionGPP() {
      return this.fetchData(
        'RDN_GHGEmissionGPP',
        'RDI_GHGEmissionGPP',
        'tCO2e/ล้านบาท',
        (value) => {
          return value * 1000;
        }
      );
    },
    json(params) {
      return 'data=' + encodeURIComponent(JSON.stringify(params));
    },
    requestChartImageUrl(url, params) {
      this.fetchChartImageUrl('/chart/line?type=image&' + this.json(params))
        .then((data) => {
          this.imageUrl = data;
        })
        .catch((error) => {
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
  },
};
</script>

<style scoped></style>
